<template>
    <vue-skeleton-loader
        type="rect"
        :width="387"
        :height="387"
        animation="fade"
        v-if="pieChartLoader"
    />
  <DoughnutChart 
    :chart-data="testData"
    :chart-options="options"
    :height="height"
    :width="width"
    v-else/>
</template>

<script>
import {mapGetters} from 'vuex';
import { Doughnut as DoughnutChart } from 'vue-chartjs/legacy';

export default {
    name: "chart",
    components: {DoughnutChart},
    data: ()=>({
        testData: {
            labels: [],
            datasets: [
                {
                    data: [],
                    backgroundColor: ['#fd4e5d', '#1ec2c3', '#e0e749', '#1a5bd9','#000000','#6610f2'],
                },
            ],
        },
        options: {
            plugins: {
                legend:{
                    display: true,
                    position: 'right',
                    fullSize: false,
                    labels: {
                        boxHeight: 25,
                        boxWidth: 25,
                        // pointStyle: 'circle'
                        usePointStyle: true,
                        padding: 15
                    }
                }
            }
        }
    }),
    props:{
        width:{
            type: Number,
            default: 100
        },
        height:{
            type: Number,
            default: 100
        },
        cssClasses: {
            default: '',
            type: String
        },
        styles: {
            type: Object,
            default: () => {}
        },
        
    },
    computed:{
        ...mapGetters({
            pieChart:'pie_graph_module/PieGraphData',
            pieChartLoader:'pie_graph_module/isPending',
            pieChartFilter:'pie_graph_module/selectedFilter',
        }),
    },
    watch:{
        'pieChart':{
            handler:function(value){
                if(value.by == "top_cities"){
                    this.testData.labels = value.cities
                    this.testData.datasets[0].data = value.sales
                }
                if(value.by == "top_categories"){
                    this.testData.labels = value.categories
                    this.testData.datasets[0].data = value.sales
                }
                if(value.by == "top_products"){
                    this.testData.labels = value.products
                    this.testData.datasets[0].data = value.sales
                }
            }, 
            deep:true
        },
    },

    methods:{

    },
    async beforeMount(){
        
        if(!this.pieChart || this.pieChart.length == 0 || this.pieChart.error){
            let payload = {
                filter: 'topCities',
            }
            this.$store.dispatch('pie_graph_module/fetchPieGraphRequest',payload);
        }else{
            if(this.pieChart.by == "top_cities"){
                    this.testData.labels = this.pieChart.cities
                    this.testData.datasets[0].data = this.pieChart.sales
                }
                if(this.pieChart.by == "top_categories"){
                    this.testData.labels = this.pieChart.categories
                    this.testData.datasets[0].data = this.pieChart.sales
                }
                if(this.pieChart.by == "top_products"){
                    this.testData.labels = this.pieChart.products
                    this.testData.datasets[0].data = this.pieChart.sales
                }

        }
    }
}
</script>