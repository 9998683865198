<template>
  <div class="stickyHeadBar">
    <div class="announcementBar">
      <div class="db-container">
        <p>Lorem, ipsum dolor sit amet consectetur adipisicing elit. <a href="#.">Update Theme</a></p>
        <button>
          <svg enable-background="new 0 0 32 32" id="Слой_1" version="1.1" viewBox="0 0 32 32" xml:space="preserve" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"><path d="M17.459,16.014l8.239-8.194c0.395-0.391,0.395-1.024,0-1.414c-0.394-0.391-1.034-0.391-1.428,0  l-8.232,8.187L7.73,6.284c-0.394-0.395-1.034-0.395-1.428,0c-0.394,0.396-0.394,1.037,0,1.432l8.302,8.303l-8.332,8.286  c-0.394,0.391-0.394,1.024,0,1.414c0.394,0.391,1.034,0.391,1.428,0l8.325-8.279l8.275,8.276c0.394,0.395,1.034,0.395,1.428,0  c0.394-0.396,0.394-1.037,0-1.432L17.459,16.014z" id="Close"/><g/><g/><g/><g/><g/><g/></svg>
        </button>
      </div>
    </div>
  <div class="db-blue-section responsivePd">
      <div class="db-container">
        <div class="row">
          <div class="col-md-12 blue-section-content">
            <div class="mobie-nav-h opensideBarDashboard">
              <a href="#." @click="showSideBarDb" class="openSideBarBtn">
                <svg fill="#fff" viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg">
                  <title />
                  <g data-name="menu " id="menu_">
                    <path d="M29,6H3A1,1,0,0,0,3,8H29a1,1,0,0,0,0-2Z" />
                    <path d="M3,17H16a1,1,0,0,0,0-2H3a1,1,0,0,0,0,2Z" />
                    <path d="M25,24H3a1,1,0,0,0,0,2H25a1,1,0,0,0,0-2Z" />
                  </g>
                </svg>
              </a>
              <div class="pageItemTitle">
                <h2>{{ pageName }}</h2>
                <div class="addNewProductBtn">
                  <!-- <button>Add New Product</button> -->
                  <button>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18.144 18.141">
                      <g id="Group_24769" data-name="Group 24769" transform="translate(-0.174 -0.175)">
                        <path id="Path_51703" data-name="Path 51703" d="M18.144,9.284a3.3,3.3,0,0,1-.127.4,1.556,1.556,0,0,1-1.509.977c-1.872,0-3.745,0-5.617,0h-.224v.225c0,1.878,0,3.756,0,5.634a1.593,1.593,0,1,1-3.187.008c0-1.878,0-3.756,0-5.634v-.233H7.261q-2.817,0-5.635,0A1.593,1.593,0,1,1,1.6,7.478c1.347-.006,2.693,0,4.04,0H7.477V7.252q0-2.746,0-5.492A1.6,1.6,0,0,1,8.828.018.132.132,0,0,0,8.859,0h.425a.128.128,0,0,0,.03.018A1.6,1.6,0,0,1,10.666,1.76q0,2.746,0,5.492v.225h5.842a1.528,1.528,0,0,1,1.363.717,3.841,3.841,0,0,1,.273.665Z" transform="translate(0.174 0.175)"/>
                      </g>
                    </svg>
                  </button>
                </div>
              </div>
            </div>

            <!-- mobile-view Dashboard end-->

            <div class="blue-right-content">
              <div class="mobile-resp-icons subProFileBox">
                <ul>
                  <li>
                    <div class="searchBlock">
                      <form>
                        <div class="searchIconWrap">
                          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 17.491 17.49">
                            <path id="Path_10" data-name="Path 10" d="M16,17.49h0L11,12.5v-.79l-.27-.28a6.512,6.512,0,1,1,.7-.7l.28.27h.79l4.99,5L16,17.489ZM6.5,2A4.5,4.5,0,1,0,11,6.5,4.505,4.505,0,0,0,6.5,2Z" fill="#848484"/>
                          </svg>
                        </div>
                        <input type="text" name="search" id="search" placeholder="Search">
                        <button class="filterBtnWrap" type="button">
                          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 21.749 19.763">
                            <g id="Group_24770" data-name="Group 24770" transform="translate(-0.001 0)">
                              <path id="Path_51390" data-name="Path 51390" d="M198.877,227.422c-.479,0-.957.007-1.436,0a.259.259,0,0,0-.284.194,2.964,2.964,0,1,1-.007-2.379.288.288,0,0,0,.317.212q1.459-.014,2.918,0a.985.985,0,0,1,.314,1.917,1.53,1.53,0,0,1-.433.059c-.463.007-.926,0-1.389,0" transform="translate(-179.623 -209.631)"/>
                              <path id="Path_51391" data-name="Path 51391" d="M2.456,113.589c.486,0,.973-.007,1.459,0a.276.276,0,0,0,.3-.2,2.967,2.967,0,1,1,.03,2.42.374.374,0,0,0-.41-.259q-1.447.016-2.895,0a.959.959,0,0,1-.935-.889.978.978,0,0,1,.713-1.035,1.45,1.45,0,0,1,.366-.037c.455,0,.911,0,1.366,0" transform="translate(-0.004 -104.694)"/>
                              <path id="Path_51392" data-name="Path 51392" d="M258.6,0a2.964,2.964,0,1,1-2.976,2.939A2.961,2.961,0,0,1,258.6,0" transform="translate(-239.797)"/>
                              <path id="Path_51393" data-name="Path 51393" d="M6.966,33.771q-2.94,0-5.88,0a.979.979,0,0,1-1-1.22.936.936,0,0,1,.732-.724,1.445,1.445,0,0,1,.344-.033q5.811,0,11.621,0a1.463,1.463,0,0,1,.454.066.982.982,0,0,1-.3,1.909c-.471.011-.941,0-1.412,0H6.966" transform="translate(-0.055 -29.822)"/>
                              <path id="Path_51394" data-name="Path 51394" d="M4.969,255.405c1.3,0,2.609,0,3.914,0a.983.983,0,0,1,.953.675.934.934,0,0,1-.345,1.089,1.272,1.272,0,0,1-.644.207c-2.6.014-5.2.012-7.8.007a.991.991,0,1,1,.013-1.978q1.957,0,3.914,0" transform="translate(0 -239.595)"/>
                              <path id="Path_51395" data-name="Path 51395" d="M196.631,145.668c-1.3,0-2.609,0-3.914,0a.983.983,0,0,1-.112-1.962c.023,0,.046,0,.069,0q3.96,0,7.92,0a.957.957,0,0,1,.961.777.968.968,0,0,1-.919,1.183c-.586.016-1.173.006-1.76.006h-2.246" transform="translate(-179.832 -134.804)"/>
                            </g>
                          </svg>
                        </button>
                      </form>
                    </div>
                  </li>
                  <li>
                    <a class="addListBtn" aria-hidden="true" id="addNewDropDown" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18.144 18.141">
                        <g id="Group_24769" data-name="Group 24769" transform="translate(-0.174 -0.175)">
                          <path id="Path_51703" data-name="Path 51703" d="M18.144,9.284a3.3,3.3,0,0,1-.127.4,1.556,1.556,0,0,1-1.509.977c-1.872,0-3.745,0-5.617,0h-.224v.225c0,1.878,0,3.756,0,5.634a1.593,1.593,0,1,1-3.187.008c0-1.878,0-3.756,0-5.634v-.233H7.261q-2.817,0-5.635,0A1.593,1.593,0,1,1,1.6,7.478c1.347-.006,2.693,0,4.04,0H7.477V7.252q0-2.746,0-5.492A1.6,1.6,0,0,1,8.828.018.132.132,0,0,0,8.859,0h.425a.128.128,0,0,0,.03.018A1.6,1.6,0,0,1,10.666,1.76q0,2.746,0,5.492v.225h5.842a1.528,1.528,0,0,1,1.363.717,3.841,3.841,0,0,1,.273.665Z" transform="translate(0.174 0.175)" fill="#fff"/>
                        </g>
                      </svg>
                    </a>
                    <div class="dropdown-menu" aria-labelledby="addNewDropDown">
                      <button class="dropdown-item">Add New Product</button>
                    </div>
                  </li>
                  <li>
                    <router-link class="helpIconBtn" to="/help">
                      <svg id="Layer_1" style="enable-background:new 0 0 512 512;" version="1.1" viewBox="0 0 512 512" xml:space="preserve" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"><g><path d="M345.1,77.1C317.6,56.2,286.6,49,247.3,49c-29.8,0-55.3,6.1-75.5,19.7C142,89,128,123.1,128,177h76.8   c0-14.4-1.4-29.9,7-43.2c8.4-13.3,20.1-23.5,40.2-23.5c20.4,0,30.9,5.9,40.8,18.1c8.4,10.4,11.6,22.8,11.6,36   c0,11.4-5.8,21.9-12.7,31.4c-3.8,5.6-8.8,10.6-15.1,15.4c0,0-41.5,24.7-56.1,48.1c-10.9,17.4-14.8,39.2-15.7,65.3   c-0.1,1.9,0.6,5.8,7.2,5.8c6.5,0,56,0,61.8,0c5.8,0,7-4.4,7.1-6.2c0.4-9.5,1.6-24.1,3.3-29.6c3.3-10.4,9.7-19.5,19.7-27.3   l20.7-14.3c18.7-14.6,33.6-26.5,40.2-35.9c11.3-15.4,19.2-34.4,19.2-56.9C384,123.5,370.5,96.4,345.1,77.1z M242,370.2   c-25.9-0.8-47.3,17.2-48.2,45.3c-0.8,28.2,19.5,46.7,45.5,47.5c27,0.8,47.9-16.6,48.7-44.7C288.8,390.2,269,371,242,370.2z"/></g></svg>
                    </router-link>
                  </li>
                  <li class="dropdown pl-0">
                      <a href="#."  aria-hidden="true" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        <div class="subHeaderProfileImg">
                          <img :src="$store.state.user.image ? base_image_url+settings.store_id+'/user/'+ $store.state.user.image : '/images/user-avatar-filled.svg'">
                        </div>
                        <p>{{username}}</p>
                        <span class="arrowIconbox">
                            <!-- <img src='/images/dropDownArrow.svg' alt=""> -->
                            <svg xmlns="http://www.w3.org/2000/svg" width="10.724" height="5.362" viewBox="0 0 10.724 5.362">
                                <path id="dropDownArrow" d="M1873,51l5,5,5-5Z" transform="translate(-1872.638 -50.85)" stroke="#000" stroke-width="0.3"/>
                            </svg>
                        </span>
                      </a>
                      <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                        <button class="dropdown-item" @click.prevent="$router.push({path:'/settings/profile'})">Profile</button>
                        <button class="dropdown-item" @click.prevent="$router.push({path:'/settings/store-settings'})">Settings</button>
                        <button class="dropdown-item" @click.prevent="logout">Logout</button>
                      </div>
                  </li>
                </ul>
                <!-- <div class="dasboardDateTime">
                    <p>{{new Date().toLocaleString("en-US",{weekday: 'long'})}}, {{new Date().toLocaleString("en-US",{day: 'numeric'})}} {{new Date().toLocaleString("en-US",{month: 'long'})}} {{new Date().toLocaleString("en-US",{year: 'numeric'})}}</p>
                </div> -->
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters} from 'vuex';
import Swal from 'sweetalert2'
export default {

  data:()=>({
    base_image_url: process.env.VUE_APP_STORE_IMAGE_BASE_URL,
    username: '',
  }),
  props: ['pageName'] ,
  methods:{
    // showSideBarDb() {
    //     var element = document.getElementsByTagName("body")[0];
    //     element.classList.remove("hideSideBar");
    // },
    showSideBarDb(){

      var element = document.getElementsByTagName("body")[0];
      element.classList.remove("hideSideBar");
      element.classList.add("activeIpad");

    },
    async logout(){

      Swal.fire({
        title: 'Do you really want to logout?',
        showDenyButton: false,
        showCancelButton: true,
        confirmButtonText: 'Yes',
        cancelButtonText: 'No',
        confirmButtonColor: '#15223D',
        showLoaderOnConfirm: true
      }).then( async (result) => {

        if(result.isConfirmed){

          let res = await this.$axios.post('/logout');
          if(res.data.status_code == "1000"){

            this.$notify({
              type: 'success',
              title: 'Success',
              message: res.data.message,
            });

            Swal.fire('Logged Out Successfully', '', 'success');
  
            this.$cookies.remove("meri_web_shop.current_user");

            this.$store.dispatch('authLoggedOut');

            this.$router.push({path: '/login'});

            this.$store.commit('accounts_module/CLEAR_MODULE');
            this.$store.commit('bills_module/CLEAR_MODULE');
            this.$store.commit('blogCategories_module/CLEAR_MODULE');
            this.$store.commit('blogs_module/CLEAR_MODULE');
            this.$store.commit('content_blocks/CLEAR_MODULE');
            this.$store.commit('coupons_module/CLEAR_MODULE');
            this.$store.commit('customers_module/CLEAR_MODULE');
            this.$store.commit('expenses_module/CLEAR_MODULE');
            this.$store.commit('bar_graph_module/CLEAR_MODULE');
            this.$store.commit('pie_graph_module/CLEAR_MODULE');
            this.$store.commit('line_graph_module/CLEAR_MODULE');
            this.$store.commit('home_banner_module/CLEAR_MODULE');
            this.$store.commit('gallery_module/CLEAR_MODULE');
            this.$store.commit('notifications_module/CLEAR_MODULE');
            this.$store.commit('orders_module/CLEAR_MODULE');
            this.$store.commit('productCategories_module/CLEAR_MODULE');
            this.$store.commit('products_module/CLEAR_MODULE');
            this.$store.commit('redirect/CLEAR_MODULE');
            this.$store.commit('robot/CLEAR_MODULE');
            this.$store.commit('seo_config/CLEAR_MODULE');
            this.$store.commit('settings_module/CLEAR_MODULE');
            this.$store.commit('shipping_module/CLEAR_MODULE');
            this.$store.commit('dashboard_stats/CLEAR_MODULE');
            this.$store.commit('order_stats/CLEAR_MODULE');
            this.$store.commit('product_stats/CLEAR_MODULE');
            this.$store.commit('store_categories_module/CLEAR_MODULE');
            this.$store.commit('themes_module/CLEAR_MODULE');
            this.$store.commit('subscription_module/CLEAR_MODULE');
            this.$store.commit('vendor_module/CLEAR_MODULE');
            this.$store.commit('page_details_module/CLEAR_MODULE');
            
          }

        }

      });

    }
  },
  computed:{
    ...mapGetters({
      settings:'settings_module/settings'
    })
  },
  async beforeMount(){

    if(!this.settings || this.settings.length == 0 || this.settings.error){

      this.$store.dispatch('settings_module/fetchStoreSetting');

    }
    
    this.username = this.$store.state.user.first_name + " " + this.$store.state.user.last_name;

  }
}
</script>

<style>
  
</style>